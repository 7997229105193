<template>
  <div class="view-container view-container--row-layout">
    <template v-if="recipe">
      <div class="recipe-info-block">
        <div class="d-flex justify-space-between">
          <h1>{{ recipe.name }}</h1>
          <router-link :to="`/edit-recipe/${recipe.id}`">Aanpassen</router-link>
        </div>
        <p class="recipe-description" v-html="description">{{}}</p>
        <!-- <div class="recipe-image"></div> -->
        <p v-if="recipe.number_of_people">
          <b>Aantal personen:</b> {{ recipe.number_of_people }}
        </p>
        <p v-if="recipe.time"><b>Tijd:</b> {{ recipe.time }}</p>
      </div>
      <div class="recipe-ingredients">
        <h2 class="recipe-ingredients__tile">Ingredienten</h2>
        <ul class="recipe-ingredients__list">
          <li
            class="recipe-ingredients__list__item"
            v-for="ingredient in recipe.ingredients"
            :key="ingredient.id"
          >
            {{ `${ingredient.amount} ${ingredient.description}` }}
          </li>
        </ul>
      </div>
      <div class="recipe-steps">
        <h2>Stappen</h2>
        <ol class="recipe-steps__list">
          <li
            class="recipe-steps__list__item"
            v-for="step in recipe.steps"
            :key="step.id"
          >
            {{ step.description }}
          </li>
        </ol>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recipe: null,
    };
  },
  computed: {
    recipeId() {
      return this.$route.params.id;
    },
    description() {
      if(this.recipe === null) return '';
      return this.recipe.description.replace(/\n/g, "<br />");
    },
  },
  async mounted() {
    if (this.recipeId) {
      const recipe = await this.$store.dispatch("getRecipeById", this.recipeId);

      if (recipe) {
        this.recipe = recipe;
      }
    }
  },
};
</script>
