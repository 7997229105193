<template>
  <form @submit.prevent="submit">
    <div class="form-group">
      <input
        v-model="recipe.name"
        class="form-control"
        type="text"
        id="name"
      >
      <label for="#name">Naam</label>
    </div>
    <div class="form-group">
      <ContentEditable
        v-model="recipe.description"
        class="form-control"
      />
      <label for="#description">Omschrijving</label>
    </div>
    <div class="form-group">
      <input
        v-model="recipe.number_of_people"
        class="form-control"
        type="number"
        id="number-of-people"
      >
      <label for="#number-of-people">Aantal personen</label>
    </div>
    <div class="form-group">
      <input
        v-model="recipe.time"
        class="form-control"
        type="text"
        id="time"
      >
      <label for="#time">Tijd</label>
    </div>

    <h1
      class="form-heading form-heading--clickable"
      @click="addIngredient"
    >Ingredienten <span class="form-heading__add-sign">+</span></h1>

    <template v-if="recipe">
      <div
        class="relation-input-line"
        v-for="(ingredient, index) in recipe.ingredients"
        :key="index + 'ingredient'"
      >
        <div class="relation-input-line__number">{{index + 1}}</div>
        <input
          placeholder="Hoeveelheid"
          v-model="ingredient.amount"
          class="form-control form-control--small-input form-control--margin-inline"
        />
        <input
          placeholder="Ingredient"
          v-model="ingredient.description"
          class="form-control form-control--small-input"
        />
        <div
          class="relation-input-line__remove-button"
          @click="removeIngredient(index, ingredient.id)"
        >-</div>
      </div>
    </template>

    <h1
      class="form-heading form-heading--clickable"
      @click="addStep"
    >Stappen <span class="form-heading__add-sign">+</span></h1>

    <template v-if="recipe">
      <div
        class="relation-input-line"
        v-for="(step, index) in recipe.steps"
        :key="step.hash"
      >
        <div class="relation-input-line__number">{{step.order}}</div>
        <ContentEditable
          v-model="step.description"
          class="form-control"
        />
        <div
          class="relation-input-line__remove-button"
          @click="removeStep(index, step.id)"
        >-</div>
      </div>
    </template>

    <button
      type="submit"
      @click.prevent="submit"
    >Opslaan</button>
  </form>
</template>

<script>
import ContentEditable from './formEditableDiv'

export default {
  props: ['recipe'],
  components: { ContentEditable },
  data () {
    return {
      name: '',
      description: '',
      number_of_people: '',
      time: ''
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    },
    addStep () {
      this.recipe.steps.push({
        description: '',
        order: this.recipe.steps.length + 1
      })
    },
    removeStep (index, id) {
      if (id) {
        this.$store.dispatch('deleteStep', id)
      }

      this.recipe.steps.splice(index, 1)
      this.recipe.steps = this.recipe.steps.map((step, index) => {
        step.order = index + 1

        return step
      })
    },
    addIngredient () {
      this.recipe.ingredients.push({
        amount: '',
        description: ''
      })
    },
    removeIngredient (index, id) {
      if (id) {
        this.$store.dispatch('deleteIngredient', id).then(() => {
          this.recipe.ingredients.splice(index, 1)
        })
      } else {
        this.recipe.ingredients.splice(index, 1)
      }
    }
  }
}
</script>