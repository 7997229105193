<template>
  <div class="view-container">
    <RecipeForm @submit="submit" :recipe="recipe"/>
  </div>
</template>

<script>
import RecipeForm from '../components/recipeForm'

export default {
  data () {
    return {
      recipe: {
        name: '',
        description: '',
        number_of_people: '',
        time: '',
        steps: [],
        ingredients: []
      }
    }
  },
  components: {
    RecipeForm
  },
  methods: {
    async submit() {
      await this.$store.dispatch('saveRecipe', this.recipe)
      this.$router.push('/')
    }
  }
}
</script>