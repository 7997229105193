<template>
  <div 
    ref="editable"
    contenteditable
    :class="className"
    v-on="listeners">
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueSet: false
    }
  },
  props: {
    className: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    listeners() {
      return {...this.$listeners, input: this.onInput}
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText)
    }
  },
  watch: {
    value(val) {
      if(this.valueSet) return

      // if value already set when mounted
      if (this.$refs.editable.innerText) {
        this.valueSet = true
        return
      }
      
      this.$refs.editable.innerText = val;
      this.valueSet = true
    }
  },
  mounted() {
    // if initialised with value, set it otherwise it get's picked up by the watcher
    if(this.value) {
      this.$refs.editable.innerText = this.value
    }
  }
}
</script>